import React, { useContext, useEffect, useState } from 'react';
import { ResultsListWrapper } from 'ToolboxComponents/webapp/pages/whisperers-view/components/results-list/results-list.styles';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import ScrollComponent from 'ToolboxComponents/commons/infinite-scroll/infinite-scroll';
import Text from 'ToolboxComponents/commons/texts/text/text';
import WhispererCard from 'ToolboxComponents/commons/cards/whisperer-card/whisperer-card';

const ResultsList = ({ firstLoad, nbWhisperers, updateLimit, whisperersList, whisperersLoaded }) => {
  const theme = useContext(ThemeContext).state;

  const [enoughWhisperers, setEnoughWhisperers] = useState(true);

  useEffect(() => {
    const resultsWidth = document.getElementById('whisperers-results-container').offsetWidth-2*30;
    const whisperersPerRow = Math.floor(resultsWidth/280);
    if (whisperersPerRow > whisperersList.length) {
      setEnoughWhisperers(false);
    } else {
      setEnoughWhisperers(true);
    }
  }, [whisperersList]);

  return (
    <ResultsListWrapper enoughWhisperers={enoughWhisperers} theme={theme}>
      <div className='presentation'>
        <h2 className='title'>
          <Text path='page.whisperers.title' data={{ nbWhisperers: nbWhisperers }} />
        </h2>
        <Text path='page.whisperers.description' className='subtitle' />
      </div>
      <ScrollComponent
        allDataLoaded={whisperersLoaded}
        data={whisperersList}
        firstLoad={firstLoad}
        getNextData={(data) => updateLimit(data)}
        hideLoader={true}
        limit={10}
        type='whisperers'
        theme={theme}
      >
        <div id='whisperers-results-container' className='results-container'>
          {whisperersList.map(whisperer => (
            <WhispererCard key={whisperer.id} whisperer={whisperer} />
          ))}
        </div>
      </ScrollComponent>
    </ResultsListWrapper>
  );
};

ResultsList.propTypes = {
  firstLoad: PropTypes.bool.isRequired,
  nbWhisperers: PropTypes.number.isRequired,
  updateLimit: PropTypes.func.isRequired,
  whisperersList: PropTypes.array.isRequired,
  whisperersLoaded: PropTypes.bool.isRequired,
};

export default ResultsList;
