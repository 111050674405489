import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InfiniteScrollWrapper } from 'ToolboxComponents/commons/infinite-scroll/infinite-scroll.styles';

const ScrollComponent = props => {
  const { data, limit, allDataLoaded, type, theme } = props;
  return (
    <InfiniteScroll
      dataLength={data}
      next={() => props.getNextData({loaded: data.length, limit: limit})}
      hasMore={!allDataLoaded}
      loader={
        <InfiniteScrollWrapper type={type} theme={theme}>
          <span>Chargement</span>
          <div className='progress-line' />
        </InfiniteScrollWrapper>
      }
      style={{ overflow: 'hidden' }}
    >
      {props.children}
    </InfiniteScroll>
  )
};

ScrollComponent.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  getNextData: PropTypes.func.isRequired,
  allDataLoaded: PropTypes.bool.isRequired,
  firstLoad: PropTypes.bool,
  theme: PropTypes.object,
}

export default ScrollComponent;
