import React, { useContext, useEffect, useState } from 'react';
import { WhispererCardWrapper } from 'ToolboxComponents/commons/cards/whisperer-card/whisperer-card.styles';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import Avatar from 'ToolboxAssets/img/default-photo.png';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import PropTypes from 'prop-types';
import SuperWhispererImg from 'ToolboxAssets/img/super-whisperer-400.png';

import { SUPER_WHISPERER_MIN_SECRETS } from 'ToolboxParams/globals';

const WhispererCard = ({ whisperer }) => {
  return (
    <WhispererCardWrapper>
      <Anchor code='public' pageParams={`/${whisperer.id}`}>
        <div className='block-photo'>
          {whisperer.photo !== null && whisperer.photo !== '' ?
            <ImageResize src={whisperer.photo} alt='' className='photo' />
            : <img src={Avatar} className='photo' />
          }
          {whisperer.accountSecretCount >= SUPER_WHISPERER_MIN_SECRETS &&
            <img className='super-whisperer' src={SuperWhispererImg} />
          }
        </div>
        <div className="description">
          <span className='firstName'>{whisperer.firstName}</span>
          <span className='nb-secrets'>{`${whisperer.accountSecretCount} secret${whisperer.accountSecretCount > 1 ? 's' : ''}`}</span>
        </div>
      </Anchor>
    </WhispererCardWrapper>
  );
};

WhispererCard.propTypes = {
  whisperer: PropTypes.object.isRequired,
};

export default WhispererCard;
