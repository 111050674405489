import React from 'react';
import ResultsList from 'ToolboxComponents/webapp/pages/whisperers-view/components/results-list/results-list';
import { WhispererWrapper } from 'ToolboxComponents/webapp/pages/whisperers-view/whisperers-view.styles';
import PropTypes from 'prop-types';

const WhisperersView = ({ firstLoad, nbWhisperers, updateLimit, whisperersList, whisperersLoaded }) => {
  return (
    <WhispererWrapper>
      <ResultsList
        firstLoad={firstLoad}
        nbWhisperers={nbWhisperers}
        updateLimit={updateLimit}
        whisperersList={whisperersList}
        whisperersLoaded={whisperersLoaded}
      />
    </WhispererWrapper>
  );
};

WhisperersView.propTypes = {
  firstLoad: PropTypes.bool.isRequired,
  nbWhisperers: PropTypes.number.isRequired,
  updateLimit: PropTypes.func.isRequired,
  whisperersList: PropTypes.array.isRequired,
  whisperersLoaded: PropTypes.bool.isRequired,
};

export default WhisperersView;
