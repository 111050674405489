import styled from 'styled-components';

export const InfiniteScrollWrapper = styled.div`
  align-items: center;
  background: ${props => props.theme && props.theme.color
    ? 'white'
    : props.type === 'products'
      ? 'lightgray'
      : '#f5f5f5'
  };
  display: flex;
  flex-direction: column;
  font-size: 25px;
  font-weight: 500;
  height: 100px;
  justify-content: space-evenly;
  padding: 5px;

  .progress-line, .progress-line:before {
    border-radius: 10px;
    height: 20px;
    width: 500px;
  }
  .progress-line {
    border: ${props => props.theme && props.theme.color ? `1px solid ${props.theme.color.secondaryColor}` : '' };
    background: ${props => props.type === 'products' ? '#f5f5f5' : 'white'};
    display: flex;
  }
  .progress-line:before {
    background-color: ${props => props.theme && props.theme.color ? props.theme.color.primaryColor : '#0074D9'};
    content: '';
    animation: running-progress 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  @keyframes running-progress {
    0% { margin-left: 0px; margin-right: 480px; }
    50% { margin-left: 480px; margin-right: 0px; }
    100% { margin-left: 0px; margin-right: 480px; }
  }
`;
