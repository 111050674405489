import styled from 'styled-components';

export const ResultsListWrapper = styled.div`
  .presentation {
    margin: 0px 10px;
    margin-bottom: 20px;
    .title {
      font-family: ${props => props.theme.font.secondaryFont.name};
      font-size: ${props =>`${(24 * props.theme.font.secondaryFont.scaling) / 100}px`};
      font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
      margin-bottom: 0;
    }
    .subtitle {
      font-size: ${props =>`${(12 * props.theme.font.secondaryFont.scaling) / 100}px`};
    }
  }
  .results-container {
    display: grid !important;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, 105px);
    justify-content: space-around;
    margin-bottom: 50px;
  }

  @media only screen and (min-width: 768px) {
    .results-container {
      gap: 30px;
      grid-template-columns: repeat(auto-fit, 170px);
      margin-bottom: 30px;
    }
    .presentation {
      margin-bottom: 30px;
      .title {
        font-size: ${props =>`${(26 * props.theme.font.secondaryFont.scaling) / 100}px`};
      }
      .subtitle {
        font-size: ${props =>`${(16 * props.theme.font.secondaryFont.scaling) / 100}px`};
      }
    }
  }
`;
