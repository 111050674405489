import styled from 'styled-components';

export const WhispererWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  @media only screen and (min-width: 768px) {
    margin-top: 0;
    padding-top: 30px;
  }
`;
