import styled from 'styled-components';

export const WhispererCardWrapper = styled.div`
  position: relative;
  .block-photo {
    display: flex;
    justify-content: center;
  }
  .photo {
    border: 4px solid white;
    border-radius: 50px;
    height: 100px;
    object-fit: cover;
    width: 100px;
  }
  .super-whisperer {
    border: 2px solid white;
    border-radius: 20px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: rotate(345deg);
    width: 40px;
  }
  .description {
    align-items: center;
    color: #3d3d3d;
    display: flex;
    flex-direction: column;
  }
  .firstName {
    font-size: 15px;
    text-align: center;
  }
  .nb-secrets {
    font-size: 13px;
  }


  @media only screen and (min-width: 768px) {
    .photo {
      border-radius: 75px;
      height: 150px;
      width: 150px;
    }
    .super-whisperer {
      border-radius: 30px;
      height: 60px;
      right: -15px;
      width: 60px;
    }
    .firstName {
      font-size: 18px;
    }
    .nb-secrets {
      font-size: 15px;
    }
  }
`;
