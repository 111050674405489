import React, { useContext, useEffect , useState } from 'react';
import { Helmet } from "react-helmet";
import { WhiteLabelContext } from "ToolboxUtils/web/context/context";
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import WhisperersView from 'ToolboxComponents/webapp/pages/whisperers-view/whisperers-view';
import Loader from 'ToolboxComponents/commons/loader/loader';

import { NB_PRODUCTS_INFINITE_SCROLL } from 'ToolboxParams/globals';
import { PAGE_LOAD_WAITING } from 'Params/globals';

const Whisperers = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [firstWhisperersLoaded, setFirstWhisperersLoaded] = useState(false);
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);
  const [limit, setLimit] = useState(NB_PRODUCTS_INFINITE_SCROLL);
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [whisperersLoaded, setWhisperersLoaded] = useState(false);
  const [whisperers, setWhisperers] = useState([]);
  const [nbWhisperers, setNbWhisperers] = useState(0);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!firstWhisperersLoaded) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    if (whiteLabel && whiteLabel.id) {
      async function getWhisperers() {
        try {
          const whisperers = await apiChuchoteurs.get(`/accounts/whisperers`, {
            params: { limit: limit, whiteLabelId: whiteLabel.id }
          });
          setNbWhisperers(parseInt(whisperers.pop()));
          if (limit > whisperers.length){
            setWhisperersLoaded(true);
          }
          setWhisperers(whisperers);
          setFirstWhisperersLoaded(true);
        } catch (err) {
          console.log(err);
        }
      }
      getWhisperers();
    }
  }, [limit, whiteLabel]);

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  useIsContentLoaded(firstWhisperersLoaded);

  return (
    <>
      {firstWhisperersLoaded ?
        <WhisperersView
          firstLoad={firstWhisperersLoaded}
          nbWhisperers={nbWhisperers}
          updateLimit={({loaded,limit}) => setLimit(loaded+limit)}
          whisperersList={whisperers}
          whisperersLoaded={whisperersLoaded}
        />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default Whisperers;
